import { AU_MELB_TZ } from '@/libs/constants';
import { formatInTimeZone } from 'date-fns-tz';
import auLocale from 'date-fns/locale/en-AU';

type Options = {
  includeTime?: boolean;
  isDateLongFormat?: boolean;
};

const formatDate = (date: string | number | Date, options?: Options) => {
  const { isDateLongFormat, includeTime } = options || {};

  const timeFormat = 'hh:mmbbb (zzz)';
  const dateFormat = isDateLongFormat ? 'dd LLLL yyyy' : 'd LLL yyyy';

  return formatInTimeZone(
    date,
    AU_MELB_TZ,
    includeTime ? `${timeFormat} ${dateFormat}` : dateFormat,
    { locale: auLocale },
  );
};

export default formatDate;
