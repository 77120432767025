import { Container, Paragraph } from '@qga/roo-ui/components';
import { AlertIcon, BannerContainer } from './components';
import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';
import withProps from '@/v2/../components/withProps';
import BlockContent from '@sanity/block-content-to-react';
import { ComponentProps, ReactNode } from 'react';
import { AlertBannerVariants } from '@/v2/types/ui';

const Content = styled(Container)`
  display: flex;
  gap: 1rem;
`;

const TextContent = withProps({ fontSize: 'xs', mb: 0, lineHeight: '20px' })(
  Paragraph,
);

const BasicLink = styled.a`
  color: currentColor;
`;

export type AlertBannerProps = {
  variant: AlertBannerVariants;
  markdown?: string;
  sanityBlocks?: ComponentProps<typeof BlockContent>['blocks'];
  children?: ReactNode;
  noIcon?: boolean;
};

const AlertBanner = ({
  variant = 'info',
  markdown,
  sanityBlocks,
  children,
  noIcon = false,
}: AlertBannerProps) => {
  return (
    <BannerContainer data-testid="ALERT_BANNER" variant={variant}>
      <Content>
        {!noIcon && <AlertIcon variant={variant} />}
        {children ||
          (markdown && (
            <ReactMarkdown
              source={markdown}
              renderers={{
                paragraph: TextContent,
                link: BasicLink,
              }}
            />
          )) ||
          (sanityBlocks && (
            <BlockContent
              blocks={sanityBlocks}
              serializers={{
                types: { block: TextContent },
                marks: {
                  link: withProps(({ mark }: { mark: any }) => ({
                    href: mark.href,
                    ...(mark.blank
                      ? {
                          target: '_blank',
                          rel: 'noopener noreferrer nofollow external',
                        }
                      : {}),
                  }))(BasicLink),
                },
              }}
            />
          ))}
      </Content>
    </BannerContainer>
  );
};

AlertBanner.Warning = ({
  children,
  ...props
}: Omit<AlertBannerProps, 'variant'>) => (
  <AlertBanner {...props} variant="warning">
    {children}
  </AlertBanner>
);

AlertBanner.Info = ({
  children,
  ...props
}: Omit<AlertBannerProps, 'variant'>) => (
  <AlertBanner {...props} variant="info">
    {children}
  </AlertBanner>
);

export default AlertBanner;
