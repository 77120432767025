import { ComponentProps, ReactNode, useMemo } from 'react';
import { Box } from '@qga/roo-ui/components';
import { SitewideMessageVariants } from '@/v2/types/ui';

type BannerContainerProps = {
  children: ReactNode;
  variant: SitewideMessageVariants;
} & ComponentProps<typeof Box>;

const BannerContainer = ({
  children,
  variant,
  ...rest
}: BannerContainerProps) => {
  const variantStyles = useMemo(() => {
    switch (variant) {
      case 'info':
        return { backgroundColor: 'background.info', borderColor: 'secondary' };
      case 'warning':
        return {
          backgroundColor: 'background.warning',
          borderColor: 'brightSun',
        };
      default:
        return {};
    }
  }, [variant]);

  return (
    <Box borderBottom={1} py={4} {...variantStyles} {...rest}>
      {children}
    </Box>
  );
};

export default BannerContainer;
