import { UI } from '@/v2/types';

const PAGE_NAME_ROUTES: Record<UI.PageName, string[]> = {
  package: [
    '/[destinationName]/hotel-and-flight-packages/[[...params]]',
    '/destinations/[destinationName]/properties/[propertyId]/package/[[...params]]',
  ],
  deals: ['/deals'], // Exclusive offer
  destination: [
    // Destination + Theme
    '/[destinationName]/[[...params]]',
    '/destinations/[destinationName]/[[...params]]',
  ],
  checkout: ['/checkout'],
  'contact-us': ['/contact-us'],
  faqs: ['/faq'],
  'home-page': ['/'],
  campaign: ['/deals/[campaignSlug]'],
  baggage: [
    '/destinations/[destinationName]/properties/[propertyId]/extras/[[...params]]',
  ],
};

export default PAGE_NAME_ROUTES;
