import { Icon } from '@qga/roo-ui/components';
import { SitewideMessageVariants } from '@/v2/types/ui';

const AlertIcon = ({ variant }: { variant: SitewideMessageVariants }) => {
  switch (variant) {
    case 'warning':
      return (
        <Icon
          data-testid="ALERT_BANNER_ICON"
          color="brightSun"
          name="warningRounded"
        />
      );
    case 'info':
      return (
        <Icon
          data-testid="ALERT_BANNER_ICON"
          color="secondary"
          name="infoSerif"
        />
      );
    default:
      return null;
  }
};

export default AlertIcon;
