import { Box, Flex } from '@qga/roo-ui/components';
import { SanityImage } from '@/libs/components';
import { MarketingMessageIcon } from '@/v2/services/sanity/types/api/parsedResponse';

const MarketingBannerIcon = ({ icons }: { icons: MarketingMessageIcon[] }) => {
  return (
    <Flex flexDirection="row">
      {icons.map((icon) => (
        <Box
          position="relative"
          height={icon.height}
          width={icon.width}
          key={icon.toString()}
        >
          <SanityImage image={icon} alt="marketing banner icon" role="img" />
        </Box>
      ))}
    </Flex>
  );
};

export default MarketingBannerIcon;
