import PropTypes from 'prop-types';
import React from 'react';

export const AppSettingsContext = React.createContext({
  pageHeader: [],
});

const AppSettingsProvider = ({ children, ...value }) => (
  <AppSettingsContext.Provider value={value}>
    {children}
  </AppSettingsContext.Provider>
);

AppSettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppSettingsProvider;
