import { UI } from '@/v2/types';
import AlertBanner from '@/v2/components/ui/AlertBanner';
import MarketingBanner from '@/v2/components/ui/MarketingBanner';
import PAGE_NAME_ROUTES from '@/v2/constants/pageNameRoutes';
import useShowSiteMessage from '@experiences-ui/shared/v2/hooks/useShowSiteMessage';

const EXCLUDED_PATHS = ['/bookings/[bookingId]'];

export type SiteMessageProps = {
  siteMessage?: UI.Message | null;
  variant: UI.SitewideMessageVariants;
};

const SiteMessage = ({ siteMessage, variant }: SiteMessageProps) => {
  const showMessage = useShowSiteMessage(
    siteMessage,
    PAGE_NAME_ROUTES,
    EXCLUDED_PATHS,
  );

  if (!showMessage || !siteMessage?.message || !variant) {
    return null;
  }

  if (variant === 'marketing') {
    const message = siteMessage as UI.MarketingMessage;

    return (
      <MarketingBanner
        icons={message.icons}
        link={message.link}
        message={message.message}
      />
    );
  }

  return <AlertBanner variant={variant} sanityBlocks={siteMessage.message} />;
};

export default SiteMessage;
