import { Box, Container, Flex } from '@qga/roo-ui/components';
import Text from '@/components/Text';
import MarketingBannerIcon from './components/MarketingBannerIcon';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import { brandColors } from '@/lib/theme/colors';
import {
  MarketingMessageIcon,
  MarketingMessageLink,
} from '@/v2/services/sanity/types/api/parsedResponse';
import useViewPromotionEvent from '@/v2/hooks/useViewPromotionEvent';
import DataLayerAnchor from './components/DataLayerAnchor';

const BannerContainer = styled(Box)`
  background-color: white;
  border-top: 1px solid ${themeGet('colors.border')};
  border-bottom: 1px solid ${themeGet('colors.border')};
`;

const TextBox = styled(Box)`
  line-height: 0;
`;

const TextContent = styled(Text)`
  font-family: 'Jetstar';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
`;

const LinkTextContent = styled(DataLayerAnchor)`
  font-family: 'Jetstar';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
`;

export type MarketingBannerProps = {
  icons: Array<MarketingMessageIcon>;
  link: MarketingMessageLink;
  message: string;
  noIcon?: boolean;
};

const MarketingBanner = ({
  icons,
  link,
  message,
  noIcon = false,
}: MarketingBannerProps) => {
  useViewPromotionEvent({ name: message });
  return (
    <BannerContainer>
      <Container data-testid="MARKETING_BANNER">
        <Flex flexDirection="row" gap="2" px={[0, null, '4']} py="2">
          {!noIcon && <MarketingBannerIcon icons={icons} />}
          <Flex alignSelf="center">
            <TextBox>
              <TextContent color={brandColors.charcoal}>{message}</TextContent>{' '}
              <LinkTextContent as={link.url} href={link.url} name={message}>
                {link.text}
              </LinkTextContent>
            </TextBox>
          </Flex>
        </Flex>
      </Container>
    </BannerContainer>
  );
};

export default MarketingBanner;
