import { FC } from 'react';
import dataLayer from '@/v2/utils/dataLayer/dataLayer';
import { useIsFeatureFlagLoaded } from '@/libs/v2/contexts/featureFlags';
import { useGA4Events } from '@/v2/hooks/featureFlags/ga4Events/useGA4Events';

interface DataLayerAnchorProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  name?: string;
}

const DataLayerAnchor: FC<DataLayerAnchorProps> = ({
  name,
  children,
  ...rest
}) => {
  const shouldUseNewEvents = useGA4Events();
  const areFeatureFlagsReady = useIsFeatureFlagLoaded();

  const handleSelectPromotionEvent = () => {
    if (!name || !areFeatureFlagsReady || !shouldUseNewEvents) return;

    dataLayer.selectPromotionEvent({ name });
  };

  return (
    <a {...rest} onClick={handleSelectPromotionEvent}>
      {children}
    </a>
  );
};

export default DataLayerAnchor;
